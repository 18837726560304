/** @format */

body {
  margin: 0;
  font-family: Roboto, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #ca1033;
}
::-webkit-scrollbar-thumb:hover {
  background: black;
}

.ant-pagination-item-active,
.ant-pagination-item-active:hover,
.ant-pagination-options-quick-jumper input:hover,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ca1033 !important;
}
.ant-pagination-item-active a {
  color: black !important;
}
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  box-shadow: unset !important;
  border-color: #ca1033 !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  /* background-color: #f7dee3 !important; */
  background-color: white !important;
}

.ant-table-tbody > tr > td:nth-child(3):hover,
.ant-table-tbody > tr > td:nth-child(2):hover {
  cursor: pointer !important;
  /* background-color: #f7dee3 !important; */
}

/* add some changes here */
/* .ant-table-tbody > tr > td:nth-child(3),
.ant-table-tbody > tr > td:nth-child(2) {
  padding: 0 16px !important;
} */

.text-area {
  margin-top: 18px !important;
}
.ant-radio-inner::after,
.ant-btn-primary {
  background-color: #d43f5b !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #d43f5b !important;
}
.ant-btn-primary {
  border-color: unset !important;
}
.ant-btn:hover,
.ant-input:hover,
.ant-input:focus {
  border-color: #d43f5b !important;
}
button.ant-btn.ant-btn-default {
  color: black;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector:hover,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-pagination-item:hover,
.ant-picker:hover,
.ant-picker-focused,
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #d43f5b !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #d43f5b !important;
  box-shadow: unset !important;
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #d43f5b !important;
  color: #d43f5b !important;
}
.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #d43f5b !important;
}
.ant-pagination-item:hover a {
  color: black !important;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: white !important;
}

.ant-spin-dot-spin {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
}

iframe {
  padding: 54px;
  width: 100%;
  height: 641.25px;
  background-color: #fff;
}

.sc-kgUAyh.dAkKEK {
  width: 200px !important;
}
.ant-picker-range {
  /* box-shadow: rgb(0 0 0 / 20%) 0px 3px 10px; */
  background-color: #fff;
  border: 1px solid rgba(202, 17, 51, 0.1) !important;
  border-radius: 4px !important;
  /* width: 200px !important; */
}

.ant-picker-focused {
  box-shadow: unset !important;
}
.ant-picker-range .ant-picker-active-bar,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  background: #d43f5b !important;
}
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: #d43f5b !important;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner:hover {
  color: white !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #eea1ae !important;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  background: unset !important;
}

.nameDropdown {
  border-radius: 8px !important;
}

.nameDropdown > div {
  border-radius: 12px !important;
  background-color: unset !important;
  border: unset !important;
}

.nameDropdown span {
  color: black !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
  border: 1px solid rgba(202, 17, 51, 0.1) !important;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: unset !important;
}

.ant-checkbox-wrapper {
  max-width: 320px;
  width: 100%;
  margin: 12px 0 0 8px !important;
  text-align: start;
  float: left;
}

/* make complete row clickable */
.clickable {
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

button.gm-ui-hover-effect {
  display: none !important;
}

/* add style on scrollable bar chart */
.chartWrapper {
  position: relative;
  overflow: auto;
}

.chartWrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.chartAreaWrapper {
  /* width: 15000px; */
  height: 280px;
}

.anticon.ant-notification-notice-icon-info {
  color: #d43f5b !important;
}

.error {
  color: red;
  font-size: medium;
}

.showPassword {
  position: absolute;
  border: none;
  background-color: transparent;
  align-self: center;
  top: 26px;
  right: 0;
}

/* antd tabs */
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover {
  color: #d43f5b !important;
}

.ant-tabs-tab {
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}

/* ant model background blurr */
.ant-modal-wrap {
  backdrop-filter: blur(6px);
}
.modalStyle .ant-modal-header {
  text-align: center;
  border-bottom: none;
}
/* .jaUojy{
  height: 750px !important;
} */

.modalStyle .ant-modal-title {
  font-size: 24px;
  font-weight: 700;
}

.modalStyle .typography {
  text-align: start;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 8px;
}

.modalStyle .cutomerTypes {
  text-align: start;
  width: 100%;
}

.checkbox-group {
  padding: 18px !important;
  width: 100%;
}
.downloadDetails{
  position: absolute;
  left: 1000px;
  top: 476px;
  color: #D91F3D;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ant-picker-range-separator {
  padding: 0 20px 0 8px !important;
}

/* checkbox handle */
/* .checkbox-group input[type="checkbox"]:disabled + span {
  color: inherit !important;
  cursor: not-allowed !important;
  border-color: #c4c4c4 !important;
}
.ant-checkbox-disabled + span {
  color: black !important;
} */

/* 
media query add */
@media (max-width: 1200px) {
  .ant-table-tbody > tr > td:nth-child(3),
  .ant-table-tbody > tr > td:nth-child(2) {
    padding: 0 16px !important;
  }
}
