/**
 * /* Fixed sidenav, full height
 *
 * @format
 */

.menuIcon {
  position: fixed;
  top: 18px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  left: 3rem;
  z-index: 1000;
}

.sidebarContainer {
  position: relative;
}

.closeBtn {
  color: white;
  position: fixed;
  z-index: 2000;
  margin-left: 13rem;
  font-size: 22px;
  border: 1px solid #ca1033;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1%;
  cursor: pointer;
}

.closeBtn:hover {
  background-color: white;
  color: black;
}

.arrow {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: #222222;
  overflow-x: hidden;
  padding-top: 58px;
  transition: 0.8s;
  overflow-x: hidden;
}

/* Style the sidenav links and the dropdown button */
.sidenav a,
.dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* On mouse-over */
.sidenav a:hover,
.dropdown-btn:hover {
  color: #ca1033;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  background-color: black;
  padding-left: 10px;
}

.dropdown-container a {
  font-size: 16px;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}

/* Some media queries for responsiveness */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .menuIcon {
    left: 1rem;
  }
}
